import React from 'react';
import * as banner from './promo.png';

// Material-UI
import Grid from '@material-ui/core/Grid';

// Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

/* ================================================================================ */
/* ================================================================================ */
/* ====                                                                        ==== */
/* ====             Este componente fue creado a pedido de AlPogo,             ==== */
/* ====              con posibilidades de ser modificado a futuro              ==== */
/* ====                        para permitir promociones                       ==== */
/* ====                                                                        ==== */
/* ================================================================================ */
/* ================================================================================ */

const Promo = ({ classes }) => {

	return (

		<Grid
			className={classes.root}
			container
			alignItems="center"
			direction="row-reverse"
			wrap="nowrap"
			justify="center"
		>
			<img
				src={banner}
				alt=""
			/>
		</Grid>
	)
}

export default withStyles(styles)(Promo);
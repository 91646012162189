import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import AmountFormat from '../AmountFormat';

import './PaymentReceipt.css';

const ReceiptLine = props => {
	return (
		<Grid
			item
			xs={12}
			className="row"
		>
			<Typography
				variant="body"
			>
				{props.name}&nbsp;
					</Typography>
			<Typography
				variant="body"
			>
				{props.value}
			</Typography>
		</Grid>
	);
}

/** Formatea la fecha en formato "argentino".
 * 
 * Antes se usaba `Date.prototype.toLocaleString` pero hubo un problema con
 * un cliente que tenía configurado para ver las fechas en formato "yanqui".
*/
function formatDateAndTime(date) {
	return [formatDate(date), formatTime(date)];
}

function formatDate(date) {
	return [
		date.getDate(),
		date.getMonth() + 1,
		date.getFullYear()
	].join('/');
}

function formatTime(date) {
	return [
		pad(date.getHours()),
		pad(date.getMinutes())
	].join(':');
}

function pad(n) {
	if (n < 10) {
		return `0${n}`;
	} else {
		return `${n}`;
	}
}

const PaymentReceipt = ({ 
	card, 
	paymentType, 
	cardTransactionRequest, 
	cardTransactionResponse
}) => {
	const {
		fullName,
		installments,
		amountPerInstallment,
		interest
	} = card;

	const {
		organization: {
			name: organizationName
		},
		description,
		invoiceId
	} = cardTransactionRequest;

	const {
		paymentMethod,
		amountPayed,
		paymentId,
	} = cardTransactionResponse;

	const [ date, time ] = formatDateAndTime(new Date());

	let installmentsToShow = installments;

	switch(installments) {
		case 13:
			installmentsToShow = 3;       // 3 cuotas
			break;
		case 16:
			installmentsToShow = 6;        // 6 cuotas
			break;
		case 7:
			installmentsToShow = 12;        // 12 cuotas
			break;
		case 8:                         // 18 cuotas
			installmentsToShow = 18;
			break;
		case 25:                // 24 cuotas
			installmentsToShow = 24;
			break;

	}


	return (
		<Grid
			container
			direction="column"
			spacing={0}
			className="PaymentReceipt"
		>
			<ReceiptLine
				name={"Fecha y hora:"}
				value={`${date} - ${time}`}
			/>
			<ReceiptLine
				name={"Pago ID:"}
				value={paymentId}
			/>
			<ReceiptLine
				name={"ID Cupón:"}
				value={invoiceId}
			/>
			<ReceiptLine
				name={"Pagador:"}
				value={fullName}
			/>

			<ReceiptLine
				name={"Método de Pago:"}
				value={paymentMethod}
			/>
			<ReceiptLine
				name={"Importe Pagado:"}
				value={<AmountFormat value={amountPayed} />}
			/>
			{paymentType === 2 && (
				<>
					<ReceiptLine
						name={"Número de cuotas:"}
						value={installmentsToShow}
					/>
					<ReceiptLine
						name={"Monto por cuota:"}
						value={<AmountFormat value={amountPayed / installmentsToShow} />}
					/>
					<ReceiptLine
						name={"Interés:"}
						value={interest}
					/>
				</>
			)}

			<ReceiptLine
				name={"Empresa:"}
				value={organizationName} />

			<ReceiptLine
				name={"Descripción:"}
				value={description} />
			<Grid
				item
				xs={12}
				className="button-container"
			>
				<Button
					variant="contained"
					onClick={() => window.print()}
				>
					Imprimir Recibo
				</Button>
			</Grid>
		</Grid>
	);
}

export default PaymentReceipt;
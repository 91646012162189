import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = createStyles({
  levelTwoButton: {
    minWidth: "200px",
    minHeight: "48px",

    backgroundColor: "transparent",
    borderRadius: "24px",
    color: "#151F2F",
    textAlign: "center",
    verticalAlign: "center",
    /* H5 🌈 */
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",

    border: "2px solid #151F2F",

    "&:not(:disabled)": {
      "&:hover": {
        borderColor: "#10151F",
      },
    },

    "&:disabled": {
      color: "#AFB9CE",
      borderColor: "#AFB9CE",
    },
  },
});

/** The design system's Level 2 button.
 *
 * - https://www.figma.com/design/ywB38nVKWcP6H1QZWZMPux/Suite-%2F-Design-System?node-id=2-4815&m=dev
 */
function LevelTwoButton({ children, onClick, disabled, classes }) {
  return (
    <button
      className={classes.levelTwoButton}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default withStyles(styles)(LevelTwoButton);

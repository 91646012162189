import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import containerStyles from "../../containers/styles";
import Grid from "@material-ui/core/Grid";
import { organizationsId } from "../../lib/organizations";
import { DocumentOrCuitNumberFormat } from "../../components/NumberFormats/index";
import TextField from "../../components/TextField/index";
import QrCode from "./QrCode";
import { printQrVoucherPath, submitQrPayment } from "../../lib/Api";
import LevelOneButton from "../LevelOneButton";
import LevelTwoButton from "../LevelTwoButton";

class QrPaymentForm extends Component {
  constructor(props) {
    super(props);

    const {
      cardTransactionRequest: {
        contact: { email },
      },
    } = props;

    this.state = {
      /** @type {{ tag: 'form' | 'submitting' } | { tag: 'success', qrCode: string, paymentRequestUuid: string } | { tag: 'error', error: any }} */
      screen: { tag: "form" },

      fullName: "",
      documentNumber: "",
      email: email || "",

      fullNameError: null,
      documentNumberError: null,
      emailError: null,
    };
  }

  changeFullName = event => {
    this.setState({ fullName: event.target.value, fullNameError: null });
  };

  changeEmail = event => {
    this.setState({ email: event.target.value, emailError: null });
  };

  changeDocumentNumber = ({ value }) => {
    this.setState({ documentNumber: value, documentNumberError: null });
  };

  submitForm = async () => {
    const { screen } = this.state;

    if (screen.tag === "submitting") {
      return;
    }

    // --- validate

    const { fullName, documentNumber, email } = this.state;

    const fullNameError = !fullName.trim()
      ? "Dato requerido"
      : /[,\.\-0-9\?\/\#¿\!¡]/.test(fullName)
      ? "No puede tener signos de puntuación, guiones ni números"
      : null;
    const documentNumberError =
      documentNumber.length < 7
        ? "Mínimo 7 números"
        : documentNumber.length > 11
        ? "Máximo 11 números"
        : null;
    const emailError = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email)
      ? null
      : "El email es inválido";

    if (fullNameError || documentNumberError || emailError) {
      this.setState({ fullNameError, documentNumberError, emailError });
      return;
    }

    // --- call endpoint

    this.setState({ screen: { tag: "submitting" } });

    const {
      cardTransactionRequest: { cardTransactionIdentifier },
    } = this.props;

    const { error, qrCode, paymentRequestUuid } = await submitQrPayment({
      fullName,
      email,
      documentNumber,
      transactionIdentifier: cardTransactionIdentifier,
    });

    // --- handle response

    if (error) {
      this.setState({ screen: { tag: "error", error } });
    } else {
      this.setState({ screen: { tag: "success", qrCode, paymentRequestUuid } });
    }
  };

  printQrVoucher = paymentRequestUuid => {
    // unmount iframe if its present (just in case)
    this.unmountIframe();

    // create and mount a (new) iframe
    document.body.appendChild(
      Object.assign(document.createElement("iframe"), {
        src: printQrVoucherPath(paymentRequestUuid),
        id: "qr-voucher",
        style: "border: 0px; height: 1px; width: 1px; position: absolute;",
      })
    );

    // Note: The iframe opens a print screen on load
  };

  unmountIframe = () => {
    const previousIframe = document.querySelector("iframe#qr-voucher");

    if (previousIframe && previousIframe.parentElement) {
      previousIframe.parentElement.removeChild(previousIframe);
    }
  };

  onBeforeUnmount = () => {
    // we also unmount the iframe in case of failure (or for other unknown reasons this code may execute)
    this.unmountIframe();
  };

  render() {
    const {
      goBack,
      cardTransactionRequest: {
        id: cardTransactionRequestId,
        organization: { id: organizationId },
      },
    } = this.props;

    const { classes } = this.props;

    const primaryButtonClassName =
      organizationId === organizationsId.potenza ? classes.potenzaButton : "";

    // --- form

    const { fullName, documentNumber, email } = this.state;
    const { fullNameError, documentNumberError, emailError } = this.state;

    const formBody = () => (
      <Grid container spacing={32} direction="column">
        <Grid container item spacing={16} direction="row">
          <Grid item xs={12} md={7}>
            <TextField
              id="name"
              name="fullName"
              label="Nombre o Razón Social"
              fullWidth
              value={fullName}
              onChangeEvent={this.changeFullName}
              InputLabelProps={{ shrink: true }}
              margin="normal"
              variant="outlined"
              error={fullNameError}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <DocumentOrCuitNumberFormat
              id="dni"
              name="documentNumber"
              label="DNI o CUIT"
              placeholder="Solo números"
              value={documentNumber}
              onChangeEvent={this.changeDocumentNumber}
              error={documentNumberError}
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            id="email"
            name="email"
            label="Email"
            fullWidth
            value={email}
            onChangeEvent={this.changeEmail}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            variant="outlined"
            placeholder="Acá recibirás el comprobante de pago"
            error={emailError}
          />
        </Grid>
      </Grid>
    );

    const formButtons = ({ disableSubmit }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <LevelTwoButton onClick={goBack}>Atrás</LevelTwoButton>
        <LevelOneButton
          disabled={disableSubmit}
          onClick={this.submitForm}
          className={primaryButtonClassName}
        >
          Generar QR
        </LevelOneButton>
      </div>
    );

    // --- render

    const { screen } = this.state;

    switch (screen.tag) {
      case "form":
      case "submitting":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "52px",
            }}
          >
            {formBody()}
            {formButtons({ disableSubmit: screen.tag === "submitting" })}
          </div>
        );

      case "success":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <b style={{ marginTop: "8px" }}>
              Para terminar escaneá el código QR.
            </b>
            <p>También podes imprimirlo y pagar más tarde.</p>
            <span style={{ color: "#4e4e4e" }}>
              ID Solicitud: {cardTransactionRequestId}
            </span>
            <QrCode qr={screen.qrCode} />
            <LevelOneButton
              onClick={() => this.printQrVoucher(screen.paymentRequestUuid)}
              className={primaryButtonClassName}
            >
              Imprimir código QR
            </LevelOneButton>
            <span style={{ color: "#4e4e4e", fontSize: "0.75rem" }}>
              Procesado por Zenrise
            </span>
          </div>
        );

      case "error":
        return (
          <Grid container direction="column" alignItems="center">
            <b style={{ marginTop: "8px", color: "#ec392f" }}>
              Hubo un error al generar el código QR
            </b>
            <p style={{ color: "#4e4e4e" }}>
              ID Solicitud: {cardTransactionRequestId}
            </p>
          </Grid>
        );
    }
  }
}

const styles = theme => ({
  ...containerStyles.checkoutFormStyles(theme),
});

export default withStyles(styles)(QrPaymentForm);

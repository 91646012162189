import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { organizationsId } from "../lib/organizations";
import { formatAmount } from "../lib/Utils";

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "32px",
    minHeight: "200px",

    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      flexDirection: "column",
      gap: "16px",
      minHeight: "auto",
    },
  },
  organizationName: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 0,
    },
  },
  card: {
    height: "100%",
    padding: "15px",
    width: "70%",
    borderRadius: "15px",
    overflow: "visible",
  },
  description: {
    maxWidth: "50%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      textAlign: "center",
    },
    fontSize: "22px",
    lineHeight: "32px",
  },
  number: {
    color: "#FF5948",
    fontSize: "32px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  cents: {
    paddingLeft: "2px",
    fontSize: "16px",
  },
  caption: {
    fontSize: "16px",
    color: "#4D5564",
  },
});

function PaymentDetail(props) {
  const {
    classes,
    cardTransactionRequest,
    cardTransactionRequest: { organization, amount, serviceFee },
    cft,
    installmentsAmount,
  } = props;

  const servicePayment = ((amount * serviceFee) / (100 + serviceFee)).toFixed(
    2
  );

  const imageHeight =
    organization.id === 12
      ? 60
      : organization.id === 31
      ? 50
      : organization.id === organizationsId.potenza || organization.id == 274
      ? 100
      : 30;

  const amountToShow = formatAmount(
    organization.id === 12 ? Math.round(installmentsAmount) + "" : amount
  );

  return (
    <>
      <div className={classes.root}>
        <div className={classes.organizationName}>
          {organization.logo ? (
            <img
              alt="logo"
              height={imageHeight}
              width="auto"
              src={organization.logo}
            />
          ) : (
            organization.name
          )}
        </div>
        <div className={classes.description}>
          {cardTransactionRequest.description}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "8px",
          }}
        >
          <span className={classes.number}>
            {amountToShow.split(",")[0]}
            <sup className={classes.cents}>{amountToShow.split(",")[1]}</sup>
          </span>
          {cft !== 0 && organization.id !== 12 && (
            <span className={classes.caption}>
              {"CFT: " + formatAmount(cft)}
            </span>
          )}
          {serviceFee && (
            <span className={classes.caption}>
              Incluye costo de servicio: ${servicePayment}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default withStyles(styles)(PaymentDetail);

const potenzaButton = {
  background: "#045858",
  transition: "all ease 300ms",
  "&:hover:not(:disabled)": {
    filter: "brightness(1.4)",
    backgroundColor: "#045858",
  },
};

const styles = {
  checkoutWindowStyles: theme => ({
    progress: {
      zIndex: 200,
    },
    header: {
      background: `linear-gradient(to top right, #FF5948, #FAC698)`,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "32px",
      flexWrap: "wrap",
      padding: "57px 130px",

      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 600,
      color: "#151F2F",

      [theme.breakpoints.down("xs")]: {
        padding: "42px 100px",

        "& img": {
          width: "120px",
          height: "auto",
        },
      },
    },
    img: {
      width: "100px",
      height: "100px",
    },
    main: {
      padding: "67px",
      [theme.breakpoints.down("sm")]: {
        padding: "32px",
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "50px",
    },
    card: {
      width: "866px",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },

      padding: "32px",

      borderRadius: "17px",
      border: "1.419px solid #E5E8F7",
      background: "#FFF",
      boxShadow: "5.677px 5.677px 8.515px 0px rgba(0, 0, 0, 0.16)",
    },
    paymentMethodHeading: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 500,
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "28px",
      },
    },
    formCard: {
      minHeight: "9rem",
      [theme.breakpoints.down("xs")]: {
        minHeight: "11rem",
      },
    },
    alPogoHeader: {
      display: "flex",
      position: "relative",
      top: "1rem",
      justifyContent: "center",
      alignItems: "center",
      "& button": {
        position: "absolute",
        left: "20rem",
        textTransform: "none",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        position: "absolute",
        flexDirection: "column",
        "& button": {
          position: "static",
          left: 0,
        },
      },
    },
    paymentMethods: {
      display: "flex",
      gap: "12px",
      justifyContent: "center",
      width: "100%",
      flexWrap: "wrap",
    },
  }),

  checkoutFormStyles: _theme => ({
    potenzaButton,
  }),
};

export default styles;

import React, { Component } from "react";

// Api
import { fetchPaymentData } from "../lib/Api";

// Components
import CheckoutForm from "./CheckoutForm";
import BankAccountPaymentMethods from "../components/BankAccountPaymentMethods";
import DebinPaymentForm from "../components/DebinPaymentForm";
import QrPaymentForm from "../components/QrPaymentForm";
import PaymentDetail from "../components/PaymentDetail";
import Success from "../components/Templates/Success.js";
import Error from "../components/Templates/Error.js";
import Promo from "../components/Promo";
import Spinner from "../components/Loading/Spinner";
import PaymentReceipt from "../components/PaymentReceipt";

// Assets
import getUrlParameter from "../lib/Utils";
import polLogoWhite from "../images/pagos-online-white.svg";
import logo2 from "../images/zenrise-color.svg";
import { organizationsId } from "../lib/organizations";
import cardIcon from "../images/card.svg";
import debinIcon from "../images/debin.svg";
import qrIcon from "../images/qr.svg";
import bankIcon from "../images/bank.svg";

// Styles
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import PaymentMethodButton from "../components/PaymentMethodButton.js";
import LevelTwoButton from "../components/LevelTwoButton.js";
import ReturnToAlPogoButton from "../components/ReturnToAlPogoButton.js";

class CheckoutWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      installmentsAmount: 0,
      isLoading: false,
      isDataReady: false,
      isPaymentAccomplish: false,
      isError: false,
      cardTransactionRequest: {},
      /** Se usa para decidir qué pantalla mostrar:
       *
       * | paymentType  | Pantalla                     |
       * | ------------ | ---------------------------- |
       * | ""           | Selector de medio de pago    |
       * | 1            | Débito                       |
       * | 2            | Crédito/Crédito Cuota Simple |
       * | 3            | Código Red Link              |
       * | 4            | DEBIN                        |
       * | 5            | Pago con QR                  |
       */
      paymentType: "",
      /* ============================================================== */
      showPromo: false, // Esta línea es para mostrar la promo de AlPogo
      /* ============================================================== */
      retry: false,
      data: "",
      cft: 0,
      cardTransactionResponse: {},
      invoiceError: null,
      paymentError: "",
    };
  }

  componentDidMount = () => {
    const token = getUrlParameter("token");
    if (token) {
      fetchPaymentData(token)
        .then(cardTransactionRequest => {
          const {
            payed,
            organization,
            ahora12Enabled,
            creditOnly,
            debitOnly,
            description,
            amount: installmentsAmount,
            showPaymentMethodWhenAhora12IsEnabled,
          } = cardTransactionRequest;
          /* ======================================================================================== */
          /* En las siguientes líneas se encuentran valores hardcodeados a pedido del ciente PRESTATE */
          /* ======================================================================================== */
          let paymentType =
            organization.id === 31 ||
            ahora12Enabled ||
            (creditOnly && !debitOnly)
              ? 2
              : "";

          if (showPaymentMethodWhenAhora12IsEnabled && ahora12Enabled) {
            paymentType = "";
          }
          // const installmentsAmount = cardTransactionRequest.amount;
          /* ====================================================================================== */
          /* En las siguientes líneas se encuentran valores hardcodeados a pedido del ciente AlPogo */
          /* ====================================================================================== */
          const showPromo = !!(
            organization.id === organizationsId.alPogo &&
            description === "TFKING 3ra edicion"
          );
          /* ====================================================================================== */
          this.setState(prevState => ({
            ...prevState,
            paymentType,
            installmentsAmount,
            cardTransactionRequest,
            ahora12EnabledOriginalValue: ahora12Enabled,
            /* ======================================================== */
            showPromo, // Esta línea es para mostrar la promo de AlPogo
            /* ======================================================== */
            isDataReady: true,
            invoiceError: payed ? "paid" : null,
          }));
        })
        .catch(err => {
          console.log({ err });
          if (err === "ERROR CARD TRANSACTION IDENTIFIER NOT VALID")
            this.setState(prevState => ({
              ...prevState,
              invoiceError: "not valid",
            }));
          else if (err.response.data.message === "Invoice already expired")
            this.setState(prevState => ({
              ...prevState,
              invoiceError: "expired",
            }));
          else if (err.response.data.message === "Access Denied")
            this.setState(prevState => ({
              ...prevState,
              invoiceError: "forbidden",
            }));
          else if (err.response.data.message === "Invoice can not be paid")
            this.setState(prevState => ({
              ...prevState,
              invoiceError: "cannot be paid",
            }));
        });
    }
  };

  componentWillUnmount = () => {
    this.setState(prevState => ({
      ...prevState,
      retry: false,
    }));
  };

  switchLoadingState = () => {
    this.setState(prevState => ({
      ...prevState,
      isLoading: !prevState.isLoading,
    }));
  };

  handlePaymentAccomplish = response => {
    const {
      cardTransactionRequest: { backUrl },
    } = this.state;
    this.setState(prevState => ({
      ...prevState,
      isPaymentAccomplish: true,
      cardTransactionResponse: response,
    }));
    if (backUrl && backUrl.success)
      setTimeout(() => window.open(backUrl.success, "_self"), 2000);
  };

  handleRecieveInstallmentsAmount = installmentsAmount => {
    this.setState(prevState => ({
      ...prevState,
      installmentsAmount,
    }));
  };

  onClickDebit = () => {
    this.setState(prevState => ({
      ...prevState,
      paymentType: 1,
      cardTransactionRequest: {
        ...prevState.cardTransactionRequest,
        ahora12Enabled:
          prevState.cardTransactionRequest.ahora12Enabled &&
          !prevState.cardTransactionRequest
            .showPaymentMethodWhenAhora12IsEnabled, // si ahora12OptionIsEnabled tenemos que pasar a false ahora12Enabled
      },
    }));
  };

  onClickCredit = () => {
    this.setState(prevState => ({
      ...prevState,
      paymentType: 2,
      cardTransactionRequest: {
        ...prevState.cardTransactionRequest,
        ahora12Enabled:
          prevState.cardTransactionRequest.ahora12Enabled &&
          !prevState.cardTransactionRequest
            .showPaymentMethodWhenAhora12IsEnabled, // si ahora12OptionIsEnabled tenemos que pasar a false ahora12Enabled
      },
    }));
  };

  onClickLinkPagos = () => {
    this.setState(prevState => ({
      ...prevState,
      paymentType: 3,
    }));
  };

  onClickAhora12 = () => {
    this.setState(prevState => ({
      ...prevState,
      paymentType: 2,
    }));
  };

  onClickDebin = () => {
    this.setState({ paymentType: 4 });
  };

  onClickQr = () => {
    this.setState({ paymentType: 5 });
  };

  handleIsError = () => {
    const {
      cardTransactionRequest: { backUrl },
    } = this.state;
    this.setState(prevState => ({
      ...prevState,
      isError: true,
      retry: false,
    }));
    if (backUrl && backUrl.failure)
      setTimeout(() => window.open(backUrl.failure, "_self"), 2000);
  };

  handleRetry = data => {
    this.setState(prevState => ({
      ...prevState,
      retry: data,
      isError: false,
      paymentError: "",
    }));
  };

  handleData = data => {
    this.setState(prevState => ({
      ...prevState,
      data,
    }));
  };

  handleCFT = cft => {
    this.setState(prevState => ({
      ...prevState,
      cft,
    }));
  };

  handleGoBack = () => {
    this.setState(
      prevState => ({
        ...prevState,
        cardTransactionRequest: {
          ...prevState.cardTransactionRequest,
          ahora12Enabled: prevState.ahora12EnabledOriginalValue,
        },
        paymentType: "",
      }),
      () => {
        window.scrollTo(0, 0);
      }
    );
  };

  handlePaymentError = message => {
    this.setState(prevState => ({
      ...prevState,
      paymentError: message,
    }));
  };

  render() {
    const { classes, startTime } = this.props;
    const {
      paymentType,
      cardTransactionRequest,
      cardTransactionRequest: {
        organization,
        showPaymentMethodWhenAhora12IsEnabled,
        ahora12Enabled,
        /** Esta opción originalmente significa que se ocultan todos los medios de pago excepto Débito,
         * pero ahora significa que se ocultan todos los medios de pago excepto Débito, DEBIN y QR.
         * O sea, se ocultan las opciones de crédito (y adicionalmente, la opción "Código Red Link").
         */
        debitOnly: hideCreditOptions,
        creditOnly,
        qrEnabled,
        debinEnabled,
      },
      cft,
      isLoading,
      isDataReady,
      isPaymentAccomplish,
      retry,
      isError,
      data,
      showPromo,
      installmentsAmount,
      cardTransactionResponse,
      invoiceError,
      paymentError,
    } = this.state;

    const showCreditCard =
      getUrlParameter("sc") === "" || getUrlParameter("sc") === "1"
        ? true
        : false;

    // --- payment method selector

    const paymentMethodSelector = () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "52px",
        }}
      >
        <h1 className={classes.paymentMethodHeading}>
          Selecciona el medio de pago
        </h1>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "30px 73px",
          }}
        >
          {!creditOnly && (
            <PaymentMethodButton icon={cardIcon} onClick={this.onClickDebit}>
              Débito
              {showPaymentMethodWhenAhora12IsEnabled && ahora12Enabled && (
                <div className={classes.paymentMethods}>
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-visa.png"
                  />
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-mastercard.png"
                  />
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-cabal.png"
                  />
                </div>
              )}
            </PaymentMethodButton>
          )}
          {showCreditCard && !hideCreditOptions && (
            <PaymentMethodButton icon={cardIcon} onClick={this.onClickCredit}>
              Crédito
              {showPaymentMethodWhenAhora12IsEnabled && ahora12Enabled && (
                <div className={classes.paymentMethods}>
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-visa.png"
                  />
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-mastercard.png"
                  />
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-cabal.png"
                  />
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-naranja.png"
                  />
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-american.png"
                  />
                </div>
              )}
            </PaymentMethodButton>
          )}
          {!creditOnly && (
            <>
              {debinEnabled && (
                <PaymentMethodButton
                  onClick={this.onClickDebin}
                  icon={debinIcon}
                >
                  DEBIN
                </PaymentMethodButton>
              )}
              {qrEnabled && (
                <PaymentMethodButton icon={qrIcon} onClick={this.onClickQr}>
                  Generar QR
                </PaymentMethodButton>
              )}
            </>
          )}
          {showPaymentMethodWhenAhora12IsEnabled &&
            ahora12Enabled &&
            !hideCreditOptions && (
              <PaymentMethodButton
                icon={cardIcon}
                onClick={this.onClickAhora12}
              >
                Crédito Cuota Simple
                <div className={classes.paymentMethods}>
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-visa.png"
                  />
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src="/logo-mastercard.png"
                  />
                </div>
              </PaymentMethodButton>
            )}
          {organization &&
            organization.id === 71 &&
            cardTransactionRequest.linkPagoPaymentReference != null &&
            !hideCreditOptions &&
            !creditOnly && (
              <PaymentMethodButton
                icon={bankIcon}
                onClick={this.onClickLinkPagos}
              >
                Código Red Link
              </PaymentMethodButton>
            )}
        </div>
      </div>
    );

    // --- checkout form

    const checkoutForm = () => (
      <CheckoutForm
        handlePaymentError={this.handlePaymentError}
        startTime={startTime}
        showPromo={showPromo}
        cardTransactionRequest={cardTransactionRequest}
        switchLoadingState={this.switchLoadingState}
        isLoading={isLoading}
        handlePaymentAccomplish={this.handlePaymentAccomplish}
        paymentType={paymentType}
        handleIsError={this.handleIsError}
        retry={retry}
        handleRetry={this.handleRetry}
        handleData={this.handleData}
        data={data}
        handleAmountPerInstallment={this.handleAmountPerInstallment}
        handleCFT={this.handleCFT}
        handleInterest={this.handleInterest}
        goBack={this.handleGoBack}
        handleRecieveInstallmentsAmount={this.handleRecieveInstallmentsAmount}
      />
    );

    // ---

    if (invoiceError) {
      return (
        <div>
          <header className={classes.header}>
            <img alt="Pagos Online" src={polLogoWhite} />
          </header>
          <div className={classes.main}>
            {invoiceError === "cannot be paid" ? (
              <div
                className={classes.card}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  ¡Lo sentimos! Esta opción de pago se encuentra deshabilitada
                  temporalmente.
                </div>
                <div style={{ textAlign: "center" }}>
                  (<strong>IMPORTANTE:</strong> este es un problema de
                  mantenimiento de este medio de pago en particular de Expensas
                  Online, no es un error de tu administración.)
                </div>
                <br />
                <div>
                  No te preocupes, tenés estas opciones para pagar
                  electrónicamente:
                </div>
                <ul>
                  <li>
                    <a
                      href="https://www.redlink.com.ar/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkPagos
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://pagomiscuentas.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pago Mis Cuentas
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.provincianet.com.ar/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BaPro Pago
                    </a>
                  </li>
                </ul>
                <div>
                  <strong>
                    Encontrá el código de pago en la parte inferior de tu
                    expensa.
                  </strong>{" "}
                  Te dejamos un ejemplo para guiarte:
                </div>
                <img
                  src="invoice-example.png"
                  alt="ejemplo de factura"
                  className="checkout-invoice-example"
                />
              </div>
            ) : (
              <div className={classes.card}>
                {invoiceError === "expired"
                  ? "Esta factura se encuentra vencida"
                  : invoiceError === "paid"
                  ? "Esta factura ya fue pagada"
                  : invoiceError === "forbidden"
                  ? "Esta factura no puede ser pagada. Póngase en contacto con la administración."
                  : "Esta factura no existe"}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div style={isLoading ? { opacity: "0.5" } : undefined}>
          {organization &&
            [
              organizationsId.alPogo,
              organizationsId.potenza,
              organizationsId.alToke,
            ].includes(organization.id) && (
              <header className={classes.header}>
                {organization.id === organizationsId.alPogo && (
                  <ReturnToAlPogoButton
                    onClick={() => {
                      cardTransactionRequest.backUrl &&
                      cardTransactionRequest.backUrl.failure
                        ? window.open(
                            cardTransactionRequest.backUrl.failure,
                            "_self"
                          )
                        : window.history.go();
                    }}
                  >
                    Volver a AlPogo
                  </ReturnToAlPogoButton>
                )}
                <h3>Para terminar, confirmá tu pago</h3>
                <div> </div>
              </header>
            )}
          {organization &&
            ![
              organizationsId.alPogo,
              organizationsId.potenza,
              organizationsId.alToke,
            ].includes(organization.id) && (
              <header className={classes.header}>
                <img alt="Pagos Online" src={polLogoWhite} />
              </header>
            )}
          {isDataReady && (
            <div className={classes.main}>
              <Spinner isLoading={isLoading} className={classes.progress} />
              {showPromo && <Promo />}
              {isError && !retry ? (
                <div className={classes.card}>
                  <Error
                    cardTransactionRequest={cardTransactionRequest}
                    paymentError={paymentError}
                    organization={organization}
                    handleRetry={this.handleRetry}
                    redirect={
                      cardTransactionRequest.backUrl &&
                      cardTransactionRequest.backUrl.failure
                        ? true
                        : false
                    }
                  />
                </div>
              ) : isPaymentAccomplish ? (
                <div className={classes.card}>
                  <Success
                    cardTransactionRequest={cardTransactionRequest}
                    organization={organization}
                    redirect={
                      cardTransactionRequest.backUrl &&
                      cardTransactionRequest.backUrl.success
                        ? true
                        : false
                    }
                  />
                  <PaymentReceipt
                    cardTransactionRequest={cardTransactionRequest}
                    paymentType={paymentType}
                    card={data}
                    cardTransactionResponse={cardTransactionResponse}
                  />
                </div>
              ) : (
                <>
                  <div className={classes.card}>
                    <PaymentDetail
                      installmentsAmount={installmentsAmount}
                      cardTransactionRequest={cardTransactionRequest}
                      cft={cft}
                      data={data}
                    />
                  </div>
                  <div className={classes.card}>
                    {paymentType === "" && paymentMethodSelector()}
                    {(paymentType == 1 || paymentType == 2) && checkoutForm()}
                    {paymentType == 3 && (
                      <BankAccountPaymentMethods
                        cardTransactionRequest={cardTransactionRequest}
                        goBack={this.handleGoBack}
                      />
                    )}
                    {paymentType === 4 && (
                      <DebinPaymentForm
                        cardTransactionRequest={cardTransactionRequest}
                        goBack={this.handleGoBack}
                      />
                    )}
                    {paymentType === 5 && (
                      <QrPaymentForm
                        cardTransactionRequest={cardTransactionRequest}
                        goBack={this.handleGoBack}
                      />
                    )}
                  </div>
                </>
              )}
              {organization.id === organizationsId.alPogo &&
                paymentType === "" &&
                !retry && (
                  <LevelTwoButton
                    onClick={() => {
                      cardTransactionRequest.backUrl &&
                      cardTransactionRequest.backUrl.failure
                        ? window.open(
                            cardTransactionRequest.backUrl.failure,
                            "_self"
                          )
                        : window.history.go();
                    }}
                  >
                    Volver atrás
                  </LevelTwoButton>
                )}
            </div>
          )}
        </div>
      );
    }
  }
}

export default withStyles(styles.checkoutWindowStyles)(CheckoutWindow);

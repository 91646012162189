const styles = theme => ({
	root: {
		position: 'relative',
		zIndex: 6000,
		marginBottom: '1rem',
		width: '43%',
		overflow: 'hidden',
		borderRadius: 15,
		boxShadow: '1px 1px 5px #333',
		padding: '10px 20px',
		backgroundColor: '#8c267a',
		height: '10rem',
		color: '#4e4e4e',
		transition: 'ease 1s',
		'& img': {
			height: '100%',
		},
		'& div': {
			height: '6rem',
			'&:first-of-type': {
				'& img': {
					'&:first-of-type': {
						height: '75%'
					},
					'&:nth-of-type(2)': {
						height: '1rem'
					}
				}
			}
		},
		[theme.breakpoints.down('md')]: {
			'& div': {
				height: '5rem'
			}
		},
		[theme.breakpoints.down('sm')]: {
			width: '55%',
			'& div': {
				height: '6rem',
				'&:first-of-type': {
					'& img': {
						'&:first-of-type': {
							
						},
						'&:nth-of-type(2)': {
							
						}
					}
				}
			}
		},
		[theme.breakpoints.down('xs')]: {
			width: '84%',
			height: '7rem',
			'& div': {
				height: '4rem',
				'&:first-of-type': {
					'& img': {
						'&:first-of-type': {
							height: '69% !important'
						},
						'&:nth-of-type(2)': {
							height: '.7rem !important'
						}
					}
				}
			}
		}
	}
})

export default styles;
import React from "react";
import NumberFormat from "react-number-format";
import { withInputStyles, useAccesibilityProps } from "../inputs";

export const CardNumberFormat = withInputStyles(
  ({
    value,
    label,
    error,
    provider,
    onChangeEvent,
    helperText,
    disabled,
    classes,
    ...props
  }) => {
    const { labelProps, inputProps, errorMessageProps } = useAccesibilityProps({
      error,
      helperText,
      disabled,
    });

    return (
      <div className={classes.root}>
        {label && <label {...labelProps}>{label}</label>}
        <NumberFormat
          {...props}
          onValueChange={onChangeEvent}
          format={
            provider === "maestro"
              ? "########## ##### ####"
              : "#### #### #### ####"
          }
          className={classes.input}
          value={value}
          disabled={disabled}
          {...inputProps}
        />
        {errorMessageProps && (
          <span className={classes.errorMessage} {...errorMessageProps}>
            {helperText}
          </span>
        )}
      </div>
    );
  }
);

export const ExpiryNumberFormat = withInputStyles(
  ({
    value,
    label,
    error,
    helperText,
    onChangeEvent,
    classes,
    disabled,
    ...props
  }) => {
    const { labelProps, inputProps, errorMessageProps } = useAccesibilityProps({
      error,
      helperText,
      disabled,
    });

    return (
      <div className={classes.root}>
        {label && <label {...labelProps}>{label}</label>}
        <NumberFormat
          {...props}
          format="##/##"
          className={classes.input}
          value={value}
          onValueChange={onChangeEvent}
          disabled={disabled}
          {...inputProps}
        />
        {errorMessageProps && (
          <span className={classes.errorMessage} {...errorMessageProps}>
            {helperText}
          </span>
        )}
      </div>
    );
  }
);

export const DocumentNumberFormat = withInputStyles(
  ({ value, label, error, onChangeEvent, disabled, classes, ...props }) => {
    const { labelProps, inputProps, errorMessageProps } = useAccesibilityProps({
      error,
      helperText: error,
      disabled,
    });

    return (
      <div className={classes.root}>
        {label && <label {...labelProps}>{label}</label>}
        <NumberFormat
          {...props}
          thousandSeparator="."
          decimalSeparator={false}
          onValueChange={onChangeEvent}
          className={classes.input}
          maxLength={10}
          value={value}
          allowLeadingZeros={true}
          disabled={disabled}
          {...inputProps}
        />
        {errorMessageProps && (
          <span className={classes.errorMessage} {...errorMessageProps}>
            {error}
          </span>
        )}
      </div>
    );
  }
);

export const DocumentOrCuitNumberFormat = withInputStyles(
  ({ value, label, error, onChangeEvent, disabled, classes, ...props }) => {
    const { labelProps, inputProps, errorMessageProps } = useAccesibilityProps({
      error,
      helperText: error,
      disabled,
    });

    return (
      <div className={classes.root}>
        {label && <label {...labelProps}>{label}</label>}
        <NumberFormat
          {...props}
          {...inputProps}
          disabled={disabled}
          decimalSeparator={false}
          onValueChange={onChangeEvent}
          className={classes.input}
          maxLength={14}
          value={value}
          allowLeadingZeros={true}
        />
        {errorMessageProps && (
          <span className={classes.errorMessage} {...errorMessageProps}>
            {error}
          </span>
        )}
      </div>
    );
  }
);

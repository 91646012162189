import React from "react";
import LevelTwoButton from "../LevelTwoButton";

const BankAccountPaymentMethods = props => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <div>
        Estos son tus datos para pagar online por la <strong>Red LINK</strong>
      </div>
      <div>
        Rubro: <strong>Cobranzas para empresas y comercios</strong>
      </div>
      <div>
        Empresa: <strong>Zenrise</strong>
      </div>
      <div>
        ID Cliente:{" "}
        <strong>{props.cardTransactionRequest.linkPagoPaymentReference}</strong>
      </div>
      <LevelTwoButton onClick={props.goBack}>Volver</LevelTwoButton>
    </div>
  );
};

export default BankAccountPaymentMethods;

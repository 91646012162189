import React from 'react';
import NumberFormat from 'react-number-format';

const AmountFormat = ({ value }) => {
  return (
		<NumberFormat
			value={parseFloat(value)}
			displayType={'text'} 
			decimalSeparator={','} 
			thousandSeparator={'.'} 
			prefix={'$'}
			decimalScale={2} //TODO: Limitar a dos decimales?
			isNumericString={true}
		/>
	);
}

export default AmountFormat;
import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = createStyles({
  levelOneButton: {
    minWidth: "200px",
    minHeight: "48px",

    backgroundColor: "#151F2F",
    borderRadius: "24px",
    color: "white",
    textAlign: "center",
    /* H5 🌈 */
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",

    "&:not(:disabled)": {
      filter: "drop-shadow(4px 4px 8px rgba(20, 34, 114, 0.32))",

      "&:hover": {
        backgroundColor: "#10151F",
      },
    },

    "&:disabled": {
      backgroundColor: "#AFB9CE",
    },
  },
});

/** The design system's Level 1 button.
 *
 * - https://www.figma.com/design/ywB38nVKWcP6H1QZWZMPux/Suite-%2F-Design-System?node-id=2-4824&m=dev
 */
function LevelOneButton({ children, onClick, disabled, className, classes }) {
  return (
    <button
      className={[classes.levelOneButton, className].filter(Boolean).join(" ")}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default withStyles(styles)(LevelOneButton);

import React from "react";
import { withInputStyles, useAccesibilityProps } from "../inputs";

function TextField({
  classes,
  label,
  error,
  name,
  type,
  value,
  placeholder,
  onClick,
  onChangeEvent,
  onPressEnter,
  onBlurEvent,
  onFocusEvent,
  disabled,
}) {
  const handleOnPressEnter = e => {
    if (e.which === 13 && onPressEnter) onPressEnter(e, e.target.value);
  };

  const { labelProps, inputProps, errorMessageProps } = useAccesibilityProps({
    error: Boolean(error),
    helperText: error,
    disabled,
  });

  return (
    <div className={classes.root}>
      <label {...labelProps}>{label}</label>
      <input
        className={classes.input}
        name={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value !== undefined ? (value !== null ? value : "") : ""}
        onChange={onChangeEvent}
        onKeyPress={handleOnPressEnter}
        onClick={onClick}
        onBlur={onBlurEvent}
        onFocus={onFocusEvent}
        // gets rid of chrome autocomplete
        autoComplete="one-time-code"
        {...inputProps}
      />
      {error && (
        <span className={classes.errorMessage} {...errorMessageProps}>
          {error}
        </span>
      )}
    </div>
  );
}

export default withInputStyles(TextField);

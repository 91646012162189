import React from "react";
import Typography from '@material-ui/core/Typography';
import "./Success.css";
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

const styles = {
	grid: {
		height: '300px'
	},
	title: {
		marginTop: '5%',
		color: '#00c853'
	}
};

function Success(props) {
	const { classes, redirect } = props;
	return (
		<Grid
			className={classes.grid}
			container
			wrap="nowrap"
			direction="column"
			justify="center"
			alignItems="center"
		>
			<Typography
				className={classes.title}
				variant="h6"
				gutterBottom
			>
				{'¡Pago realizado con éxito!'}
			</Typography>

			<svg
				className="checkmark"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 52 52"
			>
				<circle
					className="checkmark__circle"
					cx="26"
					cy="26"
					r="25"
					fill="none"
				/>
				<path
					className="checkmark__check"
					fill="none"
					d="M14.1 27.2l7.1 7.2 16.7-16.8"
				/>
			</svg>
			{redirect === false &&
				<Typography
					variant="h6"
					gutterBottom
				>
					{'Recibo de pago'}
				</Typography>
			}
		</Grid>
	);


}

export default withStyles(styles)(Success);


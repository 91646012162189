import React from "react";
import { withStyles } from "@material-ui/core";

const transition = "all 0.1s ease-out";

const styles = {
  root: {
    width: "320px",
    height: "88px",
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "20px 16px",
    borderRadius: "15px",

    transition,

    border: "1px solid transparent",
    background: "linear-gradient(90deg, #E8F0F8 0%, #F5F6FC 46.5%)",

    /** Icon wrapper; we need to apply focus styles so we use this weird selector instead of a separate class */
    "& > span:first-child": {
      width: "48px",
      height: "48px",
      flexShrink: 0,
      borderRadius: "10px",
      background:
        "linear-gradient(180deg, #D7DBF1 0%, rgba(215, 219, 241, 0.60) 100%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      transition,
    },

    color: "#151F2F",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",

    "&:hover, &:focus": {
      border: "1px solid #FF5948",
      background:
        "linear-gradient(31deg, rgba(255, 89, 72, 0.05) 38.9%, rgba(255, 89, 72, 0.02) 92.26%)",
      boxShadow: "10px 10px 20px 0px rgba(255, 89, 72, 0.10)",

      "& > span:first-child": {
        background:
          "linear-gradient(31deg, rgba(255, 89, 72, 0.60) 38.91%, rgba(255, 89, 72, 0.36) 149.67%)",
      },

      color: "#FF5948",
    },
  },
  icon: {
    width: "32px",
    height: "32px",
  },
};

function PaymentMethodButton({ icon, children, onClick, classes }) {
  return (
    <button className={classes.root} onClick={onClick}>
      <span>
        <img src={icon} className={classes.icon} />
      </span>
      {children}
    </button>
  );
}

export default withStyles(styles)(PaymentMethodButton);

import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import containerStyles from "../../containers/styles";
import { organizationsId } from "../../lib/organizations";
import { withStyles } from "@material-ui/core/styles";
import TextField from "../../components/TextField/index";
import Button from "@material-ui/core/Button";
import { checkBankAccount, submitDebinPayment } from "../../lib/Api";
import LevelOneButton from "../LevelOneButton";
import LevelTwoButton from "../LevelTwoButton";

class DebinPaymentForm extends Component {
  constructor(props) {
    super(props);

    const {
      cardTransactionRequest: {
        contact: { email },
      },
    } = props;

    this.state = {
      /** @type {'bankAccountForm' | 'submittingBankAccount' | 'validatingBankAccount' | 'submittingDebin' | 'success' | 'error'} */
      screen: "bankAccountForm",

      // Form fields
      cbuOrAlias: "",
      email,
      cbuOrAliasError: null,
      emailError: null,

      // Validation fields (returned from `checkBankAccount`)
      cbvu: null,
      holderName: null,
      holderIdNumber: null,
    };
  }

  changeCbuOrAlias = event => {
    this.setState({ cbuOrAlias: event.target.value, cbuOrAliasError: null });
  };

  changeEmail = event => {
    this.setState({ email: event.target.value, emailError: null });
  };

  submitBankAccountForm = async () => {
    const { screen } = this.state;

    if (screen !== "bankAccountForm") {
      return;
    }

    // --- validate

    const { cbuOrAlias, email } = this.state;

    const cbuOrAliasError = !cbuOrAlias.trim() ? "Dato requerido" : null;
    const emailError = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email)
      ? null
      : "El email es inválido";

    if (cbuOrAliasError || emailError) {
      this.setState({ cbuOrAliasError, emailError });
      return;
    }

    // --- call endpoint

    this.setState({ screen: "submittingBankAccount" });

    try {
      const response = await checkBankAccount(cbuOrAlias);

      this.setState({ screen: "validatingBankAccount", ...response });
    } catch (error) {
      this.setState({
        screen: "bankAccountForm",
        cbuOrAliasError: "El CBU ó Alias es incorrecto",
      });
    }
  };

  submitDebinPayment = async () => {
    const { screen } = this.state;

    if (screen !== "validatingBankAccount") {
      return;
    }

    this.setState({ screen: "submittingDebin" });

    const { email, cbvu, holderName, holderIdNumber } = this.state;

    const {
      cardTransactionRequest: { cardTransactionIdentifier },
    } = this.props;

    try {
      await submitDebinPayment({
        email,
        transactionIdentifier: cardTransactionIdentifier,
        cbvu,
        holderName,
        holderIdNumber,
      });

      this.setState({ screen: "success" });
    } catch (error) {
      this.setState({ screen: "error" });
    }
  };

  returnFromValidation = () => {
    const { screen } = this.state;

    if (screen === "validatingBankAccount") {
      this.setState({ screen: "bankAccountForm" });
    }
  };

  render() {
    // --- bank account form

    const { cbuOrAlias, email } = this.state;
    const { cbuOrAliasError, emailError } = this.state;

    const formBody = () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <TextField
          id="cbuOrAlias"
          name="cbuOrAlias"
          label="Tu CBU o Alias"
          fullWidth
          value={cbuOrAlias}
          onChangeEvent={this.changeCbuOrAlias}
          InputLabelProps={{ shrink: true }}
          margin="normal"
          variant="outlined"
          error={cbuOrAliasError}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          fullWidth
          value={email}
          onChangeEvent={this.changeEmail}
          InputLabelProps={{ shrink: true }}
          margin="normal"
          variant="outlined"
          placeholder="Acá recibirás el comprobante de pago"
          error={emailError}
        />
      </div>
    );

    const {
      goBack,
      cardTransactionRequest: {
        id: cardTransactionId,
        organization: { id: organizationId },
      },
    } = this.props;

    const { classes } = this.props;

    const primaryButtonClassName =
      organizationId === organizationsId.potenza ? classes.potenzaButton : "";

    const formButtons = ({ disableSubmit }) => (
      <Grid container direction="row" justify="center" spacing={16}>
        <Grid item>
          <LevelTwoButton onClick={goBack}>Volver</LevelTwoButton>
        </Grid>
        <Grid item>
          <LevelOneButton
            disabled={disableSubmit}
            onClick={this.submitBankAccountForm}
            className={primaryButtonClassName}
          >
            Continuar
          </LevelOneButton>
        </Grid>
      </Grid>
    );

    // --- validate bank account

    const validationBody = () => {
      const { cbvu, holderName, holderIdNumber } = this.state;

      return (
        <>
          <b>Confirmá los datos de la cuenta bancaria</b>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div>
              CBU/CVU: <b>{cbvu}</b>
            </div>
            <div>
              Nombre: <b>{holderName}</b>
            </div>
            <div>
              Número de documento: <b>{holderIdNumber}</b>
            </div>
          </div>
        </>
      );
    };

    const validateBankAccountButtons = ({ disableSubmit }) => (
      <Grid container direction="row" justify="center">
        <Button
          variant="contained"
          color="primary"
          onClick={this.returnFromValidation}
          className={classes.goBackButton}
        >
          Volver
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSubmit}
          onClick={this.submitDebinPayment}
          className={primaryButtonClassName}
          style={{ width: "auto" }}
        >
          Generar DEBIN
        </Button>
      </Grid>
    );

    // --- screens

    const { screen } = this.state;

    switch (screen) {
      case "bankAccountForm":
      case "submittingBankAccount":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "52px",
              padding: "12px",
            }}
          >
            {formBody()}
            {formButtons({
              disableSubmit: screen === "submittingBankAccount",
            })}
          </div>
        );

      case "validatingBankAccount":
      case "submittingDebin":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "12px",
            }}
          >
            {validationBody()}
            {validateBankAccountButtons({
              disableSubmit: screen === "submittingDebin",
            })}
          </div>
        );

      case "success":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "12px",
              lineHeight: "1.4rem",
              textAlign: "left",
            }}
          >
            <b>¡Para terminar debes aceptar el DEBIN!</b>
            <div>
              1. Ingresá a tu home banking o a la aplicación móvil de tu banco.
            </div>
            <div>
              2. Buscá el menú “Pagos DEBIN” e ingresá a la opción “Solicitudes
              de DEBIN Recibidas” (según el banco los nombres del menú varían).
            </div>
            <div>
              3. Aceptá el DEBIN generado, lo podés identificar como{" "}
              <b>PAGOS360</b> ó <b>POWDER S.A.</b>
            </div>
            <div>Tenés tiempo hasta las 23:59 hs.</div>
            <span style={{ color: "#4e4e4e" }}>
              ID Solicitud: {cardTransactionId}
            </span>
            <span style={{ color: "#4e4e4e", fontSize: "0.75rem" }}>
              Procesado por Zenrise
            </span>
          </div>
        );

      case "error":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "12px",
              lineHeight: "1.4rem",
              alignItems: "center",
            }}
          >
            <b style={{ marginTop: "8px", color: "#ec392f" }}>
              Hubo un error al generar el DEBIN
            </b>
            <span style={{ color: "#4e4e4e" }}>
              ID Solicitud: {cardTransactionId}
            </span>

            <Button
              variant="contained"
              color="primary"
              onClick={goBack}
              className={primaryButtonClassName}
            >
              Volver
            </Button>
          </div>
        );
    }
  }
}

const styles = theme => ({
  ...containerStyles.checkoutFormStyles(theme),
});

export default withStyles(styles)(DebinPaymentForm);

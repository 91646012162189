function getUrlParameter(name) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export default getUrlParameter;

export const formatAmount = amount =>
  window.Intl && Intl.NumberFormat
    ? Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
      }).format(parseFloat(amount))
    : parseFloat(amount).toFixed(2);

export const organizationsId = {
  alPogo: 12,
  potenza: 197, // CTRI = f7ae2f4c-a243-492d-ac40-e79492f8f91c
  alToke: 204,
  pronotebooks: 245,
  ltjtecnologia: 246,
  capi: 299,
  gabriel: 229,
  astro: 308,
  surgeTienda: 316,
  casaRoller: 203,
  casaRoller2: 449,
  daxplan: 314,
  alquilerArgentina: 322,
  zapatosComodos: 612,
  alpogo: 12,
  atermicos: 747
};

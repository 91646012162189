import React, { Component } from "react";
import { sendTransaction, fetchInstallmentsByCardType } from "../lib/Api";
import Cards from "../components/ReactCard/index";
import Grid from "@material-ui/core/Grid";
import TextField from "../components/TextField/index";
import Select from "../components/Select/index";
import {
  CardNumberFormat,
  ExpiryNumberFormat,
  DocumentNumberFormat,
} from "../components/NumberFormats/index";
import "../components/ReactCard/styles-compiled.css";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import { organizationsId } from "../lib/organizations";
import { collectionDataKount } from "../lib/kount";
import LevelOneButton from "../components/LevelOneButton";
import LevelTwoButton from "../components/LevelTwoButton";
import { formatAmount } from "../lib/Utils";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    const {
      paymentType,
      cardTransactionRequest: {
        amount,
        cardNumber,
        expiry,
        cvc,
        fullName,
        documentNumber,
        email,
        provider,
        index,
        installments,
        quota,
        result,
      },
    } = this.props;
    this.state = {
      paymentType,
      focused: "name",
      cardError: false,
      isCardValid: false,
      errorCardText: "",
      isValidExpiryDate: false,
      errorExpiryDateText: "",
      tokenExchanged: false,
      cardToken: null,
      installmentsFieldDisabled: true,
      acceptedProviders: [],
      card: {
        cardNumber: cardNumber || "",
        expiry: expiry || "",
        cvc: cvc || "",
        maxLengthCreditCardNumber: 16,
        fullName: fullName || "",
        documentNumber: documentNumber || "",
        email: email || "",
        provider: provider || "",
        installments: installments || "1",
        amount: amount || "",
        index: index || "",
        quota: quota || "",
        result: result || 0,
        installmentsTotalAmount: 0,
        newInstallmentsMethodByType: [],
        interest: 0,
        amountPerInstallment: 0,
      },
    };
  }

  componentDidMount = () => {
    const {
      cardTransactionRequest: {
        contact: { email },
        organization: { id },
        ahora12Enabled,
      },
    } = this.props;
    let providers = ["visa", "mastercard", "naranja", "cabal", "maestro"];
    if (id === 95) {
      providers = ["visa", "mastercard", "cabal", "maestro", "amex"];
    } else if (
      id === 171 ||
      id === 156 ||
      id === 235 ||
      id === 217 ||
      id === organizationsId.daxplan ||
      organizationsId.alquilerArgentina
    ) {
      providers = ["visa", "mastercard", "naranja", "amex", "cabal", "maestro"];
    } else if (id === organizationsId.casaRoller) {
      providers = ["visa", "mastercard", "cabal"];
    }
    if (ahora12Enabled) {
      providers = ["visa", "mastercard"];
    }
    this.setState(prevState => ({
      ...prevState,
      card: {
        ...prevState.card,
        email,
      },
      acceptedProviders: providers,
    }));
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      card: { provider, installments },
    } = this.state;
    const needRefreshData =
      provider !== prevState.card.provider ||
      installments !== prevState.card.installments;

    if (needRefreshData) {
      this.fetchData();
    }
  };

  getInputMaxLength = string => {
    const {
      card: { maxLengthCreditCardNumber, provider },
    } = this.state;
    let maxLength;
    // Establecer máximo de caracteres permitidos en input
    switch (string) {
      case "cardNumber":
        maxLength = maxLengthCreditCardNumber;
        break;
      case "expiry":
        maxLength = 4;
        break;
      case "cvc":
        maxLength = provider === "amex" ? 4 : 3;
        break;
      case "documentNumber":
        maxLength = 8;
        break;
      default:
        maxLength = 255;
    }
    return maxLength;
  };

  handleChange = event => {
    const { value, name } = event.target;
    const maxLength = this.getInputMaxLength(name);

    // Restringir número de caracteres donde haya un máximo
    if (value.length <= maxLength) {
      this.setState(prevState => ({
        ...prevState,
        card: {
          ...prevState.card,
          [name]: value,
        },
      }));
    }
  };

  handleChangeCard = (name, value) => {
    this.setState(prevState => ({
      ...prevState,
      card: {
        ...prevState.card,
        [name]: value,
      },
    }));
  };

  validateExpiryDate = expiry => {
    const { month, year } = this.expiryDateToMonthAndYear(expiry);
    let valid = false;
    if (month >= 1 && month <= 12) {
      const expiryDate = new Date(2000 + year, month - 1, 1);
      const currentDate = new Date();
      if (expiryDate > currentDate) {
        valid = true;
      }
    }

    if (valid) {
      this.setState(prevState => ({
        ...prevState,
        isValidExpiryDate: true,
        errorExpiryDateText: "",
      }));
    } else {
      this.updateStateInvalidExpiryDate();
    }
  };

  updateStateInvalidExpiryDate = () => {
    this.setState(prevState => ({
      ...prevState,
      isValidExpiryDate: false,
      errorExpiryDateText: "Venc. Inválido",
    }));
  };

  expiryDateToMonthAndYear = expiry => {
    let month = expiry.substr(0, 2);
    let year = expiry.substr(2, 2);
    month = Number(month.replace(/\s/g, ""));
    year = Number(year.replace(/\s/g, ""));
    month = month < 10 ? `0${month}` : month;
    return { month, year };
  };

  handleOnErrorCard = () => {
    this.setState(prevState => ({
      ...prevState,
      cardError: true,
    }));
  };

  validateCreditCardNumber = (type, isValid) => {
    const { acceptedProviders } = this.state;
    // No validar tarjeta si no es de las aceptadas
    if (acceptedProviders.indexOf(type.issuer) === -1) {
      type.issuer = "";
      isValid = false;
      type.maxLength = 16;
    }
    this.setState(prevState => ({
      ...prevState,
      isCardValid: isValid,
      errorCardText: !isValid ? "Número de tarjeta inválido" : "",
      card: {
        ...prevState.card,
        maxLengthCreditCardNumber: type.maxLength,
        provider: type.issuer,
      },
    }));
  };

  handleSendPayment = async () => {
    const {
      paymentType,
      card: {
        cardNumber: card_number,
        expiry,
        documentNumber: number,
        fullName: card_holder_name,
        cvc: security_code,
        installments,
        installmentsTotalAmount,
        email: payerEmail,
      },
      card,
    } = this.state;
    const { handleData, startTime } = this.props;
    handleData(card);
    const {
      switchLoadingState,
      handlePaymentAccomplish,
      handleIsError,
      handlePaymentError,
      cardTransactionRequest: {
        cardTransactionIdentifier: cardTransactionRequestId,
      },
    } = this.props;
    const ahora12Enabled = this.props.cardTransactionRequest.ahora12Enabled;
    const fromSubscription = this.props.cardTransactionRequest.fromSubscription;

    /* 
				"publicApiKeys": {
				"debitCard": "96e7f0d36a0648fb9a8dcb50ac06d260",
				"creditCard": {
					"installments": {
						"1": "key1",
						...,
						"12": "key2"
					},
				"default": "96e7f0d36a0648fb9a8dcb50ac06d260"
			}
		},
		Type:
		{
			debitCard: string,
			creditCard: {
				installments: {
					[key: string]: string
				},
				default: string
			}
		}
	*/
    const publicApiKeys = this.props.cardTransactionRequest.publicApiKeys;

    let selectedPublicApiKey = null;

    let kountId;

    try {
      kountId = await collectionDataKount();
    } catch (error) {
      console.log(error);
    }

    if (publicApiKeys) {
      if (paymentType == 1) {
        // DEBIT
        selectedPublicApiKey = publicApiKeys.debitCard;
      } else if (paymentType == 2) {
        // CREDIT
        selectedPublicApiKey =
          publicApiKeys.creditCard.installments[installments] ||
          publicApiKeys.creditCard.default;
      }
    }

    const [card_expiration_month, card_expiration_year] = expiry.match(/.{2}/g);
    const payerDocumentNumber = number;
    const payerCardHolder = card_holder_name;
    const elapsedTime = (new Date() - startTime) / 1000;

    const paymentData = {
      cardTransactionRequestId,
      paymentType,
      installments,
      installmentsTotalAmount,
      payerDocumentNumber,
      payerCardHolder,
      payerEmail,
      elapsedTime,
      cardData: {
        card_number,
        card_expiration_month,
        card_expiration_year,
        security_code,
        card_holder_name,
        card_holder_identification: {
          type: "dni",
          number,
        },
      },
      ahora12Enabled,
      fromSubscription,
      publicApiKey: selectedPublicApiKey,
      kountSessionId: kountId,
    };

    switchLoadingState();
    try {
      await sendTransaction(paymentData).then(({ status, result }) => {
        if (status !== 200 && status !== 201) {
          switchLoadingState();
          handleIsError();
        } else {
          switchLoadingState();
          handlePaymentAccomplish(result);
        }
      });
    } catch (ex) {
      const errorMessage =
        !(ex && ex.response && ex.response.data && ex.response.data.message) ||
        ex.response.data.message.toLowerCase() === "error general"
          ? ""
          : ex.response.data.message;
      switchLoadingState();
      handleIsError();
      handlePaymentError(errorMessage);
      console.log(ex);
    }
  };

  handleInstallmentsChange = selectionOption => {
    const {
      card: { result },
    } = this.state;
    const {
      handleCFT,
      handleRecieveInstallmentsAmount,
      cardTransactionRequest: { amount, organization },
    } = this.props;
    const installments = selectionOption.value[0];
    const i = selectionOption.value[1];
    const quota = selectionOption.value[2];
    let cft = parseFloat(selectionOption.value[3]).toFixed(2);
    let installmentsTotalAmount = selectionOption.value[3];

    if (
      organization.id === organizationsId.zapatosComodos &&
      installments === 13
    ) {
      cft = amount;
      installmentsTotalAmount = amount;
    }

    this.setState(prevState => ({
      ...prevState,
      card: {
        ...prevState.card,
        installments: installments,
        index: i,
        quota: quota,
        installmentsTotalAmount,
      },
    }));
    this.handleChangeCard("amountPerInstallment", quota);
    this.handleChangeCard(
      "interest",
      this.calculateInterest(installmentsTotalAmount - amount, amount)
    );
    handleRecieveInstallmentsAmount(installmentsTotalAmount);
    handleCFT(cft);
  };

  fetchData = async () => {
    const {
      card: { provider },
      acceptedProviders,
    } = this.state;

    const {
      cardTransactionRequest: { amount },
    } = this.props;

    if (
      acceptedProviders.indexOf(provider) === -1 ||
      provider === "" ||
      provider === "unknown" ||
      provider === "undefined" ||
      provider == null
    ) {
      this.setState(prevState => ({
        ...prevState,
        card: {
          ...prevState.card,
          result: 0,
        },
      }));
    } else {
      const result = [];
      const newInstallmentsMethodByType = await fetchInstallmentsByCardType(
        provider,
        Number(amount)
      );
      newInstallmentsMethodByType.map(item => {
        result.push(item.totalAmount);
      });
      this.setState(prevState => ({
        ...prevState,
        card: {
          ...prevState.card,
          result,
          newInstallmentsMethodByType,
        },
      }));
    }
  };

  handleFocusOnCvs = action => {
    this.setState(prevState => ({
      ...prevState,
      focused: action === "focusIn" ? "cvc" : "name",
    }));
  };

  handleChangeFormattedInput = (name, { value }) => {
    if (name === "expiry") this.validateExpiryDate(value);

    this.setState(prevState => ({
      ...prevState,
      card: {
        ...prevState.card,
        [name]: value,
      },
    }));
  };

  calculateInterest(amount, total) {
    return ((amount / total) * 100).toFixed(2) + "%";
  }

  calculateInterestWhenAhora12(installmentsNumber) {
    const {
      card: { newInstallmentsMethodByType },
    } = this.state;

    const coefficient = newInstallmentsMethodByType.find(
      item => item.installments === Number(installmentsNumber)
    ).coefficient;

    return ((coefficient - 1) * 100).toFixed(2) + "%";
  }

  handleInstallmentsLabels = (installments, totalAmount) => {
    const {
      card: { provider },
    } = this.state;
    const {
      cardTransactionRequest: { ahora12Treatment, amount, organization },
    } = this.props;

    let label;

    switch (installments) {
      /** ===============================================
		 	  Quick fix Roller House, only 2 installments
			=============================================== */

      case 2:
        label = `${formatAmount(amount / 2)} x 2 cuotas. Interés: 0.00%`;
        break;

      /** =============================================== */
      /** =============================================== */
      /** =============================================== */

      case 13:
        // label =
        //   ahora12Treatment === "CLIENT"
        //     ? `$${parseFloat(totalAmount / 3).toFixed(
        //         2
        //       )} x 3 cuotas en plan Ahora3. Interes: ${this.calculateInterestWhenAhora12(
        //         installments
        //       )}`
        //     : `$${parseFloat(amount / 3).toFixed(
        //         2
        //       )} x 3 cuotas en plan Ahora3. Interes: 0.00%`;

        if (ahora12Treatment === "CLIENT") {
          const amountToDisplay = formatAmount(totalAmount / 3);
          const interest = this.calculateInterestWhenAhora12(installments);
          label = `${amountToDisplay} × 3 cuotas en plan Cuota Simple. Interés: ${interest}`;
        } else {
          const amountToDisplay = parseFloat(amount / 3).toFixed(2);
          label = `${amountToDisplay} × 3 cuotas en plan Cuota Simple. Interés: 0.00%`;
        }
        if (organization.id === organizationsId.zapatosComodos) {
          const amountToDisplay = parseFloat(amount / 3).toFixed(2);
          label = `${amountToDisplay} × 3 cuotas en plan Cuota Simple. Interés: 0.00%`;
        }
        break;
      case 16:
        label =
          ahora12Treatment === "CLIENT"
            ? `${formatAmount(
                totalAmount / 6
              )} × 6 cuotas en plan Cuota Simple. Interés:  ${this.calculateInterestWhenAhora12(
                installments
              )}`
            : `${formatAmount(
                amount / 6
              )} × 6 cuotas en plan Cuota Simple. Interés: 0.00%`;
        break;
      case 7:
        label =
          ahora12Treatment === "CLIENT"
            ? `${formatAmount(
                totalAmount / 12
              )} × 12 cuotas en plan Cuota Simple. Interés:  ${this.calculateInterestWhenAhora12(
                installments
              )}`
            : `${formatAmount(
                amount / 12
              )} × 12 cuotas en plan Cuota Simple. Interés: 0.00%`;
        break;
      case 8:
        label =
          ahora12Treatment === "CLIENT"
            ? `${formatAmount(
                totalAmount / 18
              )} × 18 cuotas en plan Cuota Simple. Interés:  ${this.calculateInterestWhenAhora12(
                installments
              )}`
            : `${formatAmount(
                amount / 18
              )} × 18 cuotas en plan Cuota Simple. Interés: 0.00%`;
        break;
      case 25:
        label =
          ahora12Treatment === "CLIENT"
            ? `${formatAmount(
                totalAmount / 24
              )} × 24 cuotas en plan Cuota Simple. Interés:  ${this.calculateInterestWhenAhora12(
                installments
              )}`
            : `${formatAmount(
                amount / 24
              )} × 24 cuotas en plan Cuota Simple. Interés: 0.00%`;
        break;
      case 11:
        label =
          provider === "naranja"
            ? `${formatAmount(
                totalAmount
              )} × Plan Z. Interés: ${this.calculateInterest(
                totalAmount - amount,
                amount
              )}`
            : `${formatAmount(
                totalAmount / installments
              )} × ${installments} cuotas. Interés: ${this.calculateInterest(
                totalAmount - amount,
                amount
              )}`;
        break;
      default:
        label = `${formatAmount(
          totalAmount / installments
        )} × ${installments} cuotas. Interés: ${this.calculateInterest(
          totalAmount - amount,
          amount
        )}`;
        break;
    }
    return label;
  };

  handleListInstallmentsValues = (
    installments,
    index,
    amountPerInstallment,
    totalAmount
  ) => {
    const {
      cardTransactionRequest: {
        ahora12Treatment,
        ahora12Enabled,
        amount,
        organization,
      },
    } = this.props;
    const {
      card: { provider },
    } = this.state;

    const calculateAmountPerInstallmentAdmin = () => {
      switch (installments) {
        case 13:
          return (amount / 3).toFixed(2);
        case 16:
          return (amount / 6).toFixed(2);
        case 7:
          return (amount / 12).toFixed(2);
        case 8:
          return (amount / 18).toFixed(2);
        case 25:
          return (amount / 24).toFixed(2);
      }
    };

    const calculateAmountPerInstallment = () => {
      switch (installments) {
        case 13:
          if (organization == organizationsId.zapatosComodos) {
            return (amount / 3).toFixed(2);
          }
          return (totalAmount / 3).toFixed(2);
        case 16:
          return (totalAmount / 6).toFixed(2);
        case 7:
          return (totalAmount / 12).toFixed(2);
        case 8:
          return (totalAmount / 18).toFixed(2);
        case 25:
          return (totalAmount / 24).toFixed(2);
        case 11:
          return provider === "naranja"
            ? totalAmount
            : (totalAmount / installments).toFixed(2);
        default:
          return (totalAmount / installments).toFixed(2);
      }
    };

    /** ===============================================
		 	  Quick fix Roller House, only 2 installments
			=============================================== */

    totalAmount =
      (ahora12Enabled && ahora12Treatment === "ADMIN") ||
      (organization.id === organizationsId.casaRoller ||
        organization.id === organizationsId.casaRoller2)
        ? amount
        : totalAmount;

    amountPerInstallment =
      ahora12Enabled && ahora12Treatment === "ADMIN"
        ? calculateAmountPerInstallmentAdmin()
        : organization.id === organizationsId.casaRoller ||
          organization.id === organizationsId.casaRoller2
        ? (amount / 2).toFixed(2)
        : parseFloat(calculateAmountPerInstallment());

    /** =============================================== */
    /** =============================================== */
    /** =============================================== */

    return [installments, index, amountPerInstallment, totalAmount];
  };

  handleListInstallments = newInstallmentsMethodByType => {
    const {
      cardTransactionRequest: {
        organization: { id: organizationId },
        ahora12Enabled,
      },
    } = this.props;

    /** ===============================================
		 	  Quick fix Roller House, only 2 installments
			=============================================== */

    const twoInstallments = newInstallmentsMethodByType.filter(
      ({ installments }) => installments === 2
    );
    const finalInstallments =
      (organizationId === organizationsId.casaRoller ||
        organizationId === organizationsId.casaRoller2) &&
      !ahora12Enabled
        ? twoInstallments
          ? twoInstallments
          : []
        : newInstallmentsMethodByType.filter(
            ({ installments }) => installments !== 2
          );

    /** =============================================== */
    /** =============================================== */
    /** =============================================== */

    const listInstallments = finalInstallments.map(
      ({ installments, amountPerInstallment, totalAmount }, index) => {
        const value = this.handleListInstallmentsValues(
          installments,
          index,
          amountPerInstallment,
          totalAmount
        );
        const label = this.handleInstallmentsLabels(installments, totalAmount);
        return { value, label };
      }
    );
    return listInstallments;
  };

  render() {
    const {
      classes,
      paymentType,
      isLoading,
      goBack,
      cardTransactionRequest: {
        organization: { id },
        debitOnly,
        creditOnly,
        ahora12Enabled,
        ahora12AvailableInstallments,
      },
    } = this.props;
    const {
      isCardValid,
      isValidExpiryDate,
      focused,
      errorExpiryDateText,
      errorCardText,
      card: {
        cardNumber,
        fullName,
        documentNumber,
        provider,
        cvc,
        expiry,
        email,
        index,
        installments,
        maxLengthCreditCardNumber,
      },
    } = this.state;
    let {
      card: { newInstallmentsMethodByType },
    } = this.state;

    // Si es pago con tarjeta de crédito, mostrar los planes de cuotas
    let listInstallments;
    if (paymentType === 2) {
      // quick fix astro, take this to the backend only 1 installment
      if (
        organizationsId.astro == id || 
        organizationsId.alpogo == id ||
        organizationsId.atermicos == id
      ) {
        newInstallmentsMethodByType = newInstallmentsMethodByType.filter(
          ({ installments }) => [1].includes(installments)
        );
      }

      if (ahora12Enabled && ["visa", "mastercard"].includes(provider))
        newInstallmentsMethodByType = newInstallmentsMethodByType
          .filter(({ installments }) =>
            ahora12AvailableInstallments.includes(installments)
          )
          .sort(
            (a, b) =>
              newInstallmentsMethodByType.indexOf(a.installments) -
              newInstallmentsMethodByType.indexOf(b.installments)
          );
      else
        newInstallmentsMethodByType = newInstallmentsMethodByType.filter(
          ({ installments }) => ![13, 16, 7, 8, 25].includes(installments)
        );
      listInstallments = this.handleListInstallments(
        newInstallmentsMethodByType
      );
    }

    const disabled =
      isLoading ||
      !isCardValid ||
      !isValidExpiryDate ||
      !fullName ||
      documentNumber.length < 7 ||
      cvc.length < 3 ||
      (paymentType === 2 && installments === "1");

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "52px" }}>
        <Cards
          number={cardNumber}
          name={fullName}
          expiry={expiry}
          cvc={cvc}
          focused={focused}
          locale={{ valid: "Vencimiento" }}
          placeholders={{ name: "Titular" }}
          callback={this.validateCreditCardNumber}
        />
        <Grid container spacing={32}>
          {debitOnly && !creditOnly && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                color: "#333",
              }}
            >
              Sólo tarjeta de débito
            </div>
          )}
          <Grid item xs={12} md={6}>
            <CardNumberFormat
              label="Número de Tarjeta"
              disabled={isLoading}
              value={cardNumber}
              onChangeEvent={values =>
                this.handleChangeFormattedInput("cardNumber", values)
              }
              provider={provider}
              placeholder="XXXX XXXX XXXX XXXX"
              error={cardNumber.length > 0 && !isCardValid}
              helperText={
                cardNumber.length >= 4 && !provider ? errorCardText : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ExpiryNumberFormat
              label="Vencimiento"
              disabled={isLoading}
              value={expiry}
              onChangeEvent={values =>
                this.handleChangeFormattedInput("expiry", values)
              }
              placeholder="MM/YY"
              error={expiry.length > 0 && !isValidExpiryDate}
              helperText={expiry.length > 0 ? errorExpiryDateText : ""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="cvc"
              label="C.V.C"
              disabled={isLoading}
              placeholder={`XXX${provider === "amex" ? "X" : ""}`}
              value={cvc}
              onChangeEvent={this.handleChange}
              onFocusEvent={() => this.handleFocusOnCvs("focusIn")}
              onBlurEvent={() => this.handleFocusOnCvs("focusOut")}
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name="fullName"
              label="Titular de la tarjeta"
              disabled={isLoading}
              placeholder="Como figura en la tarjeta"
              fullWidth
              value={fullName}
              onChangeEvent={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DocumentNumberFormat
              name="documentNumber"
              label="D.N.I"
              disabled={isLoading}
              placeholder="Solo números"
              value={documentNumber}
              onChangeEvent={values =>
                this.handleChangeFormattedInput("documentNumber", values)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="email"
              name="email"
              label="Email"
              disabled={isLoading}
              value={email}
              fullWidth
              onChangeEvent={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          {paymentType === 2 && (
            <Grid item xs={12}>
              <Select
                hideSelectedOptions
                disabled={
                  cardNumber === "" &&
                  cardNumber.length !== maxLengthCreditCardNumber
                }
                maxMenuHeight={73}
                label="Cantidad de cuotas"
                placeholder="Seleccione cantidad de cuotas"
                value={listInstallments[index]}
                onChange={this.handleInstallmentsChange}
                options={listInstallments}
              />
            </Grid>
          )}
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {id !== 31 && debitOnly === creditOnly && (
            <LevelTwoButton onClick={goBack}>Atrás</LevelTwoButton>
          )}
          <LevelOneButton
            disabled={disabled}
            onClick={this.handleSendPayment}
            className={
              id === organizationsId.potenza ? ` ${classes.potenzaButton}` : ""
            }
          >
            Pagar
          </LevelOneButton>
        </div>
      </div>
    );
  }
}

export default withStyles(styles.checkoutFormStyles)(CheckoutForm);

import axios from "axios";

/**
 *
 * - ¡ATENCIÓN! Al ser esta pantalla creada con create-react-app,
 *      las variables ambientales deben ser precedidas por el prefijo
 *      REACT_APP. De otra manera, la aplicación las evitará.
 *      Es decir, REACT_APP_ENV_NAME=value
 *
 **/

//  const environment = 'LOCAL';
const environment = process.env.REACT_APP_SDK_ENVIRONMENT;
//  const environment = 'PROD';

let Sdk = new window.Zenrise.Sdk(environment);

export function sendTransaction(data) {
  // if(data.ahora12Enabled == false) Sdk = new window.Zenrise.Sdk(environment);
  // if(data.ahora12Enabled == true) Sdk = new window.Zenrise.Sdk(environment, 4);
  // if(data.fromSubscription == true) Sdk = new window.Zenrise.Sdk(environment, 3);

  return Sdk.processPaymentFromCardTransactionIdentifier(data);
}

export function fetchPaymentData(token) {
  return Sdk.getCardTransactionRequest(token).then(response => {
    if (response.fromSubscription) Sdk = new window.Zenrise.Sdk(environment, 3);
    if (response.ahora12Enabled) Sdk = new window.Zenrise.Sdk(environment, 4);
    return response;
  });
}

export function fetchInstallmentsByCardNumber(cardNumber, amount) {
  const firstFourDigits = cardNumber.substring(0, 4);
  return Sdk.resolveInstallmentsPerCardNumberAndAmount(firstFourDigits, amount);
}

export function fetchInstallmentsByCardType(type, amount) {
  return Sdk.resolveInstallmentsPerCardBrandAndAmount(type, amount);
}

function pagosOnlineApiDomain(environment) {
  if (environment) {
    switch (environment.toUpperCase()) {
      case "LOCAL":
        return "http://localhost:8080/v1";

      case "DEV":
        return "https://dev.api.zenrise.io/v1";

      case "PROD":
        return "https://api.zenrise.io/v1";
    }
  }

  return "unknown-environment";
}

function pagosOnlineApiEndpoint(path) {
  return [pagosOnlineApiDomain(environment), path].join(
    path.startsWith("/") ? "" : "/"
  );
}

export async function submitQrPayment(
  /** @type {{ fullName: string, email: string, documentNumber: string, transactionIdentifier: string }} */
  params
) {
  const { fullName, email, documentNumber, transactionIdentifier } = params;

  try {
    // Generates the QR in the server
    const response = await axios.post(
      pagosOnlineApiEndpoint("/wallet/qr/spot"),
      {
        transactionIdentifier,
        payerName: fullName,
        documentId: documentNumber,
        email,
      }
    );

    if (typeof response.data !== "object" || response.data === null) {
      throw new Error("submitQrPayment: Respuesta con formato inesperado");
    }

    const { paymentRequestUUID: paymentRequestUuid, qrCode } = response.data;

    if (typeof paymentRequestUuid !== "string" || typeof qrCode !== "string") {
      throw new Error("submitQrPayment: Respuesta con formato inesperado");
    }

    return { error: null, paymentRequestUuid, qrCode };
  } catch (error) {
    return { error, paymentRequestUuid: null, qrCode: null };
  }
}

const isCbu = cbuOrAlias =>
  cbuOrAlias.length === 22 && /^\d+$/.test(cbuOrAlias);

export async function checkBankAccount(cbuOrAlias) {
  /* Para testear en dev:
  return {
    cbvu: "2850590940090418135201",
    holderName: "Mauro Cano",
    holderIdNumber: "47178331",
  };
  */

  const response = await axios.get(
    pagosOnlineApiEndpoint("/wallet/data-from-cbu-or-alias"),
    {
      params: {
        type: isCbu(cbuOrAlias) ? "CBU_CVU" : "ALIAS",
        value: cbuOrAlias,
      },
    }
  );

  const { cbvu, holderName, holderIdNumber } = response.data;

  return { cbvu, holderName, holderIdNumber };
}

export async function submitDebinPayment(params) {
  const {
    email,
    transactionIdentifier,
    // These fields are returned from `checkBankAccount`
    cbvu,
    holderName,
    holderIdNumber,
  } = params;

  return axios.post(pagosOnlineApiEndpoint("/wallet/debin/spot"), {
    transactionIdentifier,
    holderIdNumber,
    holderIdType: holderIdNumber.length < 10 ? "dni" : "cuit",
    holderName,
    holderType: "cbu",
    holderCbuOrAlias: cbvu,
    holderEmail: email,
    holderPhoneNumber: null,
  });
}

function pagos360ApiDomain(environment) {
  if (environment) {
    switch (environment.toUpperCase()) {
      case "LOCAL":
        // NOTA: no sé qué conviene dejar acá
        return "https://qa.api.pagos360.com";

      case "DEV":
        return "https://qa.api.pagos360.com";

      case "PROD":
        return "https://api.pagos360.com";
    }
  }

  return "unknown-environment";
}

function pagos360ApiEndpoint(path) {
  return [pagos360ApiDomain(environment), path].join(
    path.startsWith("/") ? "" : "/"
  );
}

export function printQrVoucherPath(paymentRequestUuid) {
  return pagos360ApiEndpoint(
    `/payment-request/qr-voucher/${encodeURIComponent(
      paymentRequestUuid
    )}?print=true`
  );
}

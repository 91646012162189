import React from "react";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import "./Error.css";
import { organizationsId } from '../../lib/organizations';

const styles = {
	grid: {
		height: '700px'
	},
	button: {
		background: `linear-gradient(to right, #feb940, #f4085f)`,
		float: 'center',
		width: '12em',
		color: 'white',
		textAlign: 'center',
		margin: '10px',
		fontSize: '14px',
		'&:hover': {
			boxShadow: '0 0 10px #f4085f'
		},
		borderRadius: '20px'
	},
	potenzaButton: {
		background: '#045858',
		'&:hover': {
			boxShadow: 'none',
			filter: 'brightness(1.4)',
			backgroundColor: '#045858 !important',
			transition: 'ease 300ms'
		}
	},
	title: {
		marginTop: '5%',
		color: '#feb940'
	}
};

function Error(props) {
	const { classes, handleRetry, redirect, organization, paymentError } = props;
	return (
		<Grid
			className={classes.grid}
			container
			direction="column"
			justify="center"
			alignItems="center"
		>
			<Typography
				className={classes.title}
				variant="h4"
				gutterBottom
			>
				{'Su pago no se ha podido realizar'}
			</Typography>
			<svg
				className="alert"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 52 52"
				color="error"
			>
				<circle
					className="alert__circle"
					cx="26"
					cy="26"
					r="25"
					fill="none"
				/>
				<line
					className="alert__line"
					x1="26"
					y1="10"
					x2="26"
					y2="32"
				>
				</line>
				<circle
					className="alert__point"
					cx="26"
					cy="40"
					r="1"
					fill="none"
				/>
			</svg>
			{ redirect === false &&
				<Grid>
					<Typography
						variant="h5"
						gutterBottom
					>
						{paymentError || 'Intente nuevamente'}
					</Typography>
					<Button
						variant="contained"
						onClick={() => handleRetry(true)}
						className={`${classes.button}${organization.id === organizationsId.potenza ? ` ${classes.potenzaButton}` : ''}`}
					>
						{'Reintentar'}
					</Button>
				</Grid>
			}
		</Grid>
	);


}

export default withStyles(styles)(Error);
import { useState } from "react";
import { withStyles, createStyles } from "@material-ui/core";
import { v4 as uuid } from "uuid";

export const useAccesibilityProps = ({ error, helperText, disabled }) => {
  const [id] = useState(uuid);
  const errorMessageId = error && helperText && `${id}-errormessage`;

  return {
    labelProps: { htmlFor: id, "data-disabled": disabled },
    inputProps: {
      id,
      "aria-invalid": Boolean(error) || undefined,
      "aria-errormessage": errorMessageId || undefined,
    },
    errorMessageProps: errorMessageId ? { id: errorMessageId } : null,
  };
};

export const inputStyles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",

    /** Label */
    "& label": {
      /* Input / Head ✔️ */
      fontSize: "13px",
      fontWeight: 600,
      lineHeight: "16px",

      "&[data-disabled=true]": {
        color: "#AFB9CE",
      },
    },
  },

  input: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #AFB9CE",
    padding: "7px 15px",
    /* Body/S/Regular 🌈 */
    fontSize: "14px",
    fontWeight: 400,
    /** Line height is different from Body/S styles to center the text vertically */
    lineHeight: "32px",
    height: "48px",

    "&::placeholder, & ::placeholder": {
      color: "#7987A4",
      fontWeight: 400,
      opacity: 1,

      "&:disabled": {
        color: "#AFB9CE",
      },
    },

    "&:not(:disabled)[aria-invalid=true], & *:not(:disabled)[aria-invalid=true]": {
      borderColor: "#F5003B",
    },

    "&:disabled": {
      color: "#AFB9CE",
    },
  },

  errorMessage: {
    color: "#F5003B",
    /* Caption/Regular 🌈 */
    fontSize: "11px",
    lineHeight: "16px",
  },
});

export function withInputStyles(Component) {
  return withStyles(inputStyles)(Component);
}

import React from "react";
import Select from "react-select";
import { inputStyles, useAccesibilityProps, withInputStyles } from "../inputs";

function SelectOverride(props) {
  const { disabled, label, classes, ...otherProps } = props;

  const { labelProps, inputProps } = useAccesibilityProps({
    error: null,
    helperText: null,
    disabled,
  });

  return (
    <div className={classes.root}>
      <label {...labelProps}>{label}</label>
      <Select
        isDisabled={disabled || false}
        noOptionsMessage={() => "No hay opciones"}
        {...otherProps}
        {...inputProps}
        styles={{
          // https://react-select.com/styles
          control: _base => ({
            ...inputStyles.input,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }),
          valueContainer: _ => ({
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            overflow: "hidden",
          }),
          container: base => ({
            ...base,
          }),
          input: _ => ({}),
          placeholder: (styles, { isDisabled }) => ({
            ...styles,
            color: isDisabled ? "#AFB9CE" : "#7987A4",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }),
          indicatorSeparator: _ => ({ display: "none" }),
          dropdownIndicator: (_, { isDisabled }) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            color: isDisabled ? "#AFB9CE" : "#7987A4",
          }),
          option: base => ({
            ...base,
            height: "100%",
          }),
          menuList: base => ({
            maxHeight: "10rem",
          }),
          menu: base => ({
            ...base,
            left: "0px",
            borderRadius: 15,
            overflowX: "hidden",
          }),
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 15,
          colors: {
            ...theme.colors,
            text: "#1b2435",
            primary: "#A04077",
          },
        })}
      />
    </div>
  );
}

export default withInputStyles(SelectOverride);

import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = createStyles({
  returnToAlPogoButton: {
    cursor: "pointer",

    backgroundColor: "transparent",
    color: "#151F2F",

    display: "flex",
    alignItems: "center",
    gap: "8px",

    /* H5 🌈 */
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",

    "&:disabled": {
      color: "#AFB9CE",
    },
  },
});

/** A variation of the design system's Level 3 button.
 *
 * It has a chevron icon and the color is white because it is used in the header.
 *
 * - https://www.figma.com/design/ywB38nVKWcP6H1QZWZMPux/Suite-%2F-Design-System?node-id=2-4794&m=dev
 */
function ReturnToAlPogoButton({ children, onClick, disabled, classes }) {
  return (
    <button
      className={classes.returnToAlPogoButton}
      onClick={onClick}
      disabled={disabled}
    >
      {chevronLeftIcon}
      <span>{children}</span>
    </button>
  );
}

const chevronLeftIcon = (
  <span
    style={{
      width: "24px",
      height: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Chevron / Grande / Base">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99999 15.2695C7.99907 15.0768 7.92611 14.8925 7.79713 14.7568L1.63897 7.99816L7.79713 1.2395C7.86184 1.17053 7.91284 1.08848 7.94711 0.99822C7.98138 0.90796 7.99822 0.811324 7.99664 0.714035C7.99417 0.569448 7.95111 0.428926 7.87297 0.310423C7.79483 0.191921 7.68515 0.100819 7.55794 0.0487586C7.43074 -0.00330145 7.29179 -0.0139556 7.15885 0.0181575C7.02591 0.0502706 6.90501 0.123693 6.81161 0.229042L0.192825 7.49257C0.0691444 7.62819 -3.41363e-07 7.80948 -3.49611e-07 7.99816C-3.57858e-07 8.18684 0.0691444 8.36813 0.192824 8.50374L6.81161 15.7673C6.90549 15.8746 7.02776 15.9495 7.1624 15.9819C7.29704 16.0144 7.43778 16.003 7.5662 15.9492C7.69462 15.8954 7.80474 15.8017 7.88214 15.6804C7.95954 15.5592 8.00062 15.4159 7.99999 15.2695Z"
          fill="currentColor"
        />
      </g>
    </svg>
  </span>
);

export default withStyles(styles)(ReturnToAlPogoButton);

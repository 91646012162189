import kountSDK from '@kount/kount-web-client-sdk';
import { v4 as uuid } from 'uuid';

const kountConfig = {
    clientID: process.env.REACT_APP_KOUNT_CLIENT_ID,
    hostname: process.env.REACT_APP_KOUNT_HOSTNAME,
    isSinglePageApp: true,
    callbacks: null,
};

export function collectionDataKount() {
    return new Promise((resolve, reject) => {
        try {
            const sessionID = uuid().replace(/-/g, '');
            const sdk = kountSDK(kountConfig, sessionID);
            if (sdk) {
                resolve(sdk.sessionID);
            } else {
                resolve();
            }
        } catch (error) {
            reject(error);
        }
    });
}
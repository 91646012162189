import React, { Component, createRef } from "react";
import qrcode from "qrcode";

export default class QrCode extends Component {
  canvasRef = createRef();

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount = () => {
    const canvas = this.canvasRef.current;
    const { qr } = this.props;

    qrcode.toCanvas(canvas, qr, { scale: 5 }, (error) => {
      if (error) {
        this.setState({ error });
      }
    });
  };

  render() {
    const { error } = this.state;

    return (
      <>
        <canvas
          ref={this.canvasRef}
          style={error ? { display: "none" } : undefined}
        ></canvas>
        {error && (
          <p style={{ color: "#ec392f" }}>
            Hubo un error al generar el código QR
          </p>
        )}
      </>
    );
  }
}
